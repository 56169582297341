@import './common.scss';

.carousel {
  margin-bottom: 50px;
}

.slide-content {
  padding: 40px 0;
  border-radius: 5px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  p {
    font-size: 15px;
    width: 70%;
    font-style: italic;
  }

  .slide-author {
    font-weight: 800;
    font-size: 16px;
    font-style: normal;
  }
}

.slider-control-bottomcenter {
  ul {
    gap: 10px;
  }

  button {
    font-size: 20px;
    fill: rgba(239, 158, 149, 0.804) !important;
  }
}

.slide-button {
  color: rgb(84, 93, 66);
  border: none;
  background: none;
  width: 40px;
  height: 40px;
  font-size: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  transition: background-color 0.3s ease;

  &.slide-prev {
    left: 10px;
  }

  &.slide-next {
    right: 10px;
  }
}

@media (min-width: 768px) {
  .slide-content {
    padding: 40px;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    p {
      font-size: 17px;
    }

    .slide-author {
      font-size: 15px;
    }
  }

  .slide-button {
    width: 100px;
    height: 100px;

    &.slide-prev {
      left: 30px;
    }

    &.slide-next {
      right: 30px;
    }
  }
}

@media (min-width: 1200px) {
  .slide-content {
    p {
      font-size: 18px;
      width: 440px;
    }

    .slide-author {
      font-size: 20px;
    }
  }

  .slide-button {
    width: 100px;
    height: 100px;

    &.slide-prev {
      left: 300px;
    }

    &.slide-next {
      right: 300px;
    }
  }
}
