@import './common.scss';

.hero-section {
  padding: 50px 10px;
  margin: 0;
  background-image: url('../assets/bc-images/bc96.jpg');
  background-size: 100%;
  background-position: bottom;
  height: calc(100vh - 87px);
  width: 100%;
  background-repeat: no-repeat;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.entire-section {
  .about-container {
    background-image: none;
    background-color: white;
  }
}

.name-text {
  font-family: $playfair;
  color: rgb(40, 51, 67);
  text-align: center;
  font-size: 37px;
  width: 95%;
  margin-top: 5%;
  text-transform: uppercase;
}

.social-logos-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.home-text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  font-weight: 300;
}

.practice-text {
  width: 260px;
}

@media (min-height: 700px) {
  .entire-section {
    .about-container {
      padding: 150px 5% 50px 5%;
    }
  }

  .hero-section {
    padding: 80px 20px;
    background-size: 110%;

    .name-text {
      font-size: 40px;
    }
  }
}

@media (min-height: 800px) {
  .hero-section {
    padding: 120px 20px;
    background-size: 100%;

    .name-text {
      font-size: 45px;
    }
  }
}

@media (min-height: 850px) {
  .hero-section {
    padding: 120px 30px;

    .name-text {
      font-size: 50px;
    }
  }
}

@media (min-height: 900px) {
  .hero-section {
    padding: 150px 30px;
  }
}

@media (min-width: 450px) {
  .hero-section {
    .name-text {
      font-size: 40px;
    }
  }

  @media (min-height: 700px) {
    .hero-section {
      .name-text {
        font-size: 42px;
      }
    }
  }

  @media (min-height: 800px) {
    .hero-section {
      .name-text {
        font-size: 45px;
      }
    }
  }

  @media (min-height: 900px) {
    .hero-section {
      .name-text {
        font-size: 48px;
      }
    }
  }
}

@media (min-width: 500px) {
  .hero-section {
    .name-text {
      font-size: 40px;
    }
  }

  @media (min-height: 700px) {
    .hero-section {
      .name-text {
        font-size: 42px;
      }
    }
  }

  @media (min-height: 800px) {
    .hero-section {
      .name-text {
        font-size: 48px;
      }
    }
  }

  @media (min-height: 850px) {
    .hero-section {
      .name-text {
        font-size: 52px;
      }
    }
  }
}
// Adjust from 600 to 800 of width
@media (min-width: 600px) {
  @media (min-height: 600px) {
    .hero-section {
      background-image: url('../assets/bc-images/bc5.jpg');
      padding: 80px 50px;
    }

    .practice-text {
      width: 300px;
    }
  }

  @media (min-height: 800px) {
    .hero-section {
      .practice-text {
        font-size: 16px;
      }
    }
  }

  @media (min-height: 900px) {
    .hero-section {
      background-image: url('../assets/bc-images/bc96.jpg');
      padding: 100px 30px;

      .name-text {
        font-size: 55px;
      }
    }
  }

  @media (min-height: 1000px) {
    .hero-section {
      .name-text {
        font-size: 60px;
        margin-top: 100px;
        width: 100%;
      }

      .practice-text {
        font-size: 16px;
      }
    }
  }
}
// Adjust from 800 to 1000 of width
@media (min-width: 800px) {
  .hero-section {
    padding: 80px 50px;
    background-image: url('../assets/bc-images/bc6.jpg');
    background-size: 120%;

    .name-text {
      font-size: 50px;
    }
  }

  @media (min-height: 800px) {
    .hero-section {
      padding: 100px 50px;
      background-size: 100%;

      .name-text {
        font-size: 54px;
      }
    }
  }

  @media (min-height: 900px) {
    .hero-section {
      padding: 120px 50px;
    }
  }

  @media (min-height: 1000px) {
    .hero-section {
      .name-text {
        font-size: 58px;
      }

      .practice-text {
        font-size: 16px;
      }
    }
  }

  @media (min-height: 1100px) {
    .hero-section {
      background-size: 170%;

      .name-text {
        font-size: 58px;
      }

      .practice-text {
        font-size: 16px;
      }
    }
  }
}
// Adjust from 1000 to 1200 of width
@media (min-width: 1000px) {
  .hero-section {
    padding: 100px 0 0 0;
    height: calc(100vh);
    background-position: bottom;
    background-image: url('../assets/bc-images/bc6.jpg');
  }

  .practice-text {
    width: 300px;
  }

  @media (min-height: 700px) {
    .hero-section {
      background-size: 100%;
      padding: 150px 0 0 0;
      background-position: bottom;
    }

    .practice-text {
      width: 400px;
    }
  }

  @media (min-height: 900px) {
    .hero-section {
      padding: 240px 50px;

      .name-text {
        font-size: 60px;
      }

      .practice-text {
        font-size: 20px;
      }
    }
  }
}
// Adjust from 1200 to 1400 of width
@media (min-width: 1200px) {
  .hero-section {
    background-image: url('../assets/bc-images/bc6.jpg');
    background-size: 100%;
  }

  @media (min-height: 900px) {
    .hero-section {
      .name-text {
        font-size: 65px;
      }
    }
  }

  @media (min-height: 1100px) {
    .hero-section {
      .name-text {
        font-size: 70px;
      }

      .practice-text {
        font-size: 22px;
      }
    }
  }

  @media (min-height: 1300px) {
    .hero-section {
      .name-text {
        font-size: 77px;
      }

      .practice-text {
        font-size: 23px;
        width: 500px;
      }
    }
  }
}
// Adjust from 1400 to 1600 of width
@media (min-width: 1400px) {
  .hero-section {
    .name-text {
      font-size: 70px;
    }

    .practice-text {
      font-size: 20px;
    }
  }
}

@media (min-width: 1700px) {
  .hero-section {
    padding: 200px 0 0 0;

    .name-text {
      font-size: 80px;
    }
  }

  @media (min-height: 1100px) {
    .hero-section {
      .name-text {
        font-size: 85px;
      }
    }
  }
}
