@import './common.scss';

.thanks-page {
  background-color: #faf8f6;
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 20px;

  h1 {
    font-size: 80px;
    font-weight: 300;
    font-style: italic;
    color: #cca36f;
  }

  p {
    width: 90%;
  }

  .buttons-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;

    .submit-btn {
      background-color: #c3aeaa;
      color: white;
      width: 130px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .submit-btn:hover {
      border: 1px solid #c3aeaa;
      color: #af9a95;
      background-color: rgba(245, 222, 179, 0);
    }

    .submit-btn:nth-child(2) {
      background-color: #c3a88a;
      width: 150px;
    }

    .submit-btn:nth-child(2):hover {
      border: 1px solid #c3a88a;
      color: #c3a88a;
      background-color: rgba(245, 222, 179, 0);
    }
  }

  .social-logos-container {
    .logo-container {
      background-color: rgb(143, 138, 133);
    }
  }
}
