@import './common.scss';

.contact-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url('../assets/bc-images/bc11.jpg');
  background-size: cover;

  .social-logos-container {
    .logo-container {
      background-color: rgb(140, 162, 193);
    }
  }

  .contact-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
    margin: 0;
  }

  .opening-hours-container-about {
    border: 1px solid rgb(48, 52, 80);
    width: 95%;
    margin-top: 50px;
  }

  .opening-hours-title {
    text-align: center;
    font-size: 30px;
  }

  .contact-title {
    color: rgb(48, 52, 80);
    margin: 0 0;
    font-size: 60px;
    text-align: center;
    padding: 30% 4% 0 4%;
    font-family: $playfair;
    text-transform: uppercase;
  }

  .hours-location-container {
    border: none;
    background-image: none;
    height: 100%;
    width: 100%;
  }

  .phone-title {
    font-size: 30px;
  }

  .title-line {
    display: block;
    height: 1px;
    width: 50px;
    background-color: rgb(63, 63, 152);
    margin-bottom: 20px;
  }

  .opening-hours-text-container {
    margin-top: 20px;
    width: 90%;
  }

  .hours-div {
    display: flex;
    gap: 6px;
  }

  .week-days,
  .saturday-wednesday,
  .sunday {
    font-family: $header-text;
    font-size: 18px;
  }

  .time-icon {
    color: rgb(63, 63, 152);
    margin-top: 3px;
  }

  .phone-number {
    margin-top: 20px;
    font-size: 20px;
  }

  .contact-text {
    width: 80%;
    text-align: center;
    height: 120px;
  }

  .location-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .location-text {
    margin-top: 20px;
  }

  .map-container {
    height: 280px;
  }

  .phone-container {
    height: 320px;
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .title-footer {
    color: black;
    margin: 0;
    font-size: 30px;
  }

  form {
    width: 100%;
    padding: 50px 20px 0 20px;
    background-color: rgba(255, 255, 255, 0.472);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;

    .title-footer {
      margin-bottom: 20px;
    }
  }

  input,
  textarea {
    border: none;
    background-color: rgba(255, 255, 255, 0.019);
    border-bottom: 0.6px solid rgb(63, 70, 125);
    width: 95%;
    height: 30px;
    border-radius: 0;
    color: rgb(35, 39, 63);
  }

  input::placeholder,
  textarea::placeholder {
    color: rgb(35, 39, 63);
    font-family: $text-font;
  }

  textarea {
    height: 200px;
  }



  .submit-btn {
    background-color: white;
    border: 1px solid rgb(48, 52, 80);
    color: rgb(48, 52, 80);
    width: 150px;
    height: 40px;
    font-size: 20px;
    margin-top: 30px;
    margin-bottom: 60px;
  }

  .submit-btn:hover {
    background-color: rgb(48, 52, 80);
    border: 1px solid white;
    color: white;
  }
}

@media (min-width: 768px) {
  .contact-section {
    background-position: 0 0;

    .contact-main {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-content: center;
      align-items: center;
      gap: 30px;
    }

    .contact-container {
      .phone-container {
        width: 100%;
      }
    }

    .contact-title {
      font-size: 70px;
    }

    .opening-hours-container-about {
      width: 300px;
    }
  }

  @media (min-width: 900px) {
    .contact-section {
      .contact-main {
        width: 70%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-content: center;
        align-items: center;
        gap: 30px;
        margin-top: 30px;
      }

      .map-container {
        .map {
          width: 400px;
        }
      }

      .hours-location-container {
        height: 500px;
        margin: 0;
      }

      .opening-hours-container-about {
        width: 400px;
        height: 400px;
        margin: 0;
      }

      .phone-container {
        width: 400px;
        margin: 0;
        padding: 0;
      }
    }
  }

  @media (min-width: 1240px) {
    .contact-section {
      background-position: 0 0;
      background-size: cover;

      .footer-input {
        max-width: none;
      }

      .contact-main {
        display: grid;
        row-gap: 50px;
        justify-items: center;
        align-items: center;
        column-gap: 100px;
      }

      // form {
      //   grid-column: 1/4;
      //   grid-row: 2;
      //   justify-self: stretch;
      //   margin-top: 20px;
      //   width: 100%;

      //   input,
      //   textarea {
      //     background-color: white;
      //     border: 1px solid rgb(63, 63, 152);
      //     width: 500px;
      //     height: 50px;
      //     border-radius: 10px;
      //     padding: 10px 20px;
      //   }

      //   textarea {
      //     height: 300px;
      //   }
      // }

      .title-footer {
        color: black;
        margin: 0;
      }
    }
  }
}
