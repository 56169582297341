
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Red+Rose:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&family=Prata&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;1,200;1,300&family=Playfair+Display:ital,wght@0,400;0,500;0,600;1,400;1,500&display=swap');

$clear-pink: #fff4f2;
$chocolate: #30221e;
$blackFooter: #292929;
$title-font: 'Red Rose';
$text-font: 'Raleway';
$orange: #d25c33;
$header-text: 'Prata';
$salmon:  #f9a392;
$playfair : 'Playfair Display';
$pink: rgb(246, 233, 225);

h1,
h2,
h3,
h4 {
  font-family: $playfair;
  margin: 0;
  padding: 0;
  color: #30221e;
}

p {
  font-family: $playfair;
  margin: 0;
  padding: 0;
  color: #30221e;
  font-size: 15px;
}

li {
  list-style: none;
  color: #30221e;
  font-family: $playfair;
  text-decoration: none;
}

body {
  overflow-x: hidden;
}

button {
  border: none;
}

a {
  text-decoration: none !important;
  font-family: $playfair;
  font-weight: 300;
}

a:hover {
  color: #30221e;
}

* {
  margin: 0;
  padding: 0;
}

.section {
  padding: 50px 2%;
}

.section-title {
  text-align: center;
  font-size: 37px;
  text-transform: uppercase;
  font-weight: 300;
}

.gift-card-page {
  display: flex;
  justify-content: center;
  align-items: center;
}

.layout {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 20;
  height: 100%;
  top: 0;
  right: 0;
  width: 100%;
  gap: 30px;

  h1 {
    color: rgba(41, 41, 41, 0.884);
    font-size: 30px;
  }

  background-color: rgba(246, 232, 225, 0.559);
}

@media (min-width: 425px) {
  .section-title {
    font-size: 45px;
  }

  p {
    font-size: 16px;
  }
}

@media (min-width: 768px) {
  body {
    background-color: rgb(236, 235, 233);
    overflow-x: hidden;
  }

  .section {
    padding: 70px 2%;
  }

  .section-title {
    font-size: 70px;
  }
}

@media (min-width: 769px) {
  .section {
    padding: 150px 2%;
  }

  p {
    font-size: 17px;
  }

  .layout {
    gap: 30px;

    h1 {
      font-size: 45px;
    }

    background-color: rgba(246, 232, 225, 0.559);
  }
}

@media (min-width: 1024px) {
  .section {
    padding: 150px 2%;
  }

  .section-title {
    font-size: 80px;
    max-width: 700px;
  }

  p {
    font-size: 18px;
  }
}

@media (min-width: 1440px) {
  .section {
    padding: 170px 2%;
  }
}

@media (min-width: 1640px) {
  .section {
    padding: 220px 2%;
  }

  .section-title {
    font-size: 120px;
  }
}

@media (min-width: 1800px) {
  .section {
    padding: 250px 2%;
  }

  .section-title {
    font-size: 130px;
  }

  p {
    font-size: 19px;
  }
}
