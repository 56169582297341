.gift-card-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .massages-p-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
  }

  .massage-p-container {
    height: 300px;
    border: 0.4px solid rgb(199, 199, 199);
    width: 100%;
  }

  .massage-p-text {
    border: none;
    gap: 10px;
    background-color: rgba(255, 255, 255, 0);
  }

  .book-now-btn.book-massage {
    color: rgb(245, 238, 231);
    background-color: rgba(168, 168, 168, 0.748);
    font-size: 17px;
    border: 0.5px solid rgb(245, 238, 231);
    text-align: center;
  }

  .wellness {
    background-image: url('./../assets/bc-images/bc2.jpg');
    background-size: cover;
  }

  .customized {
    background-image: url('./../assets/bc-images/bc4.jpg');
  }

  .ayurvedic {
    background-image: url('./../assets/bc-images/bc13.jpg');
  }
}

.error-message {
  color: rgb(173, 10, 10);
  padding: 10px;
  opacity: 0;
}

.error-message.active {
  opacity: 1;
}

.cart-btn {
  height: 60px;
  width: 180px;
}

.gift-card-prices-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

@media (min-width: 768px) {
  .gift-card-section {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .massage-p-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 80%;

      .image-desktop-wellness,
      .image-desktop-customized,
      .image-desktop-ayurvedic {
        display: none;
      }

      .massage-p-text {
        width: 60%;
        height: auto;
        background-color: rgba(245, 222, 179, 0);
      }

      .book-now-btn.book-massage {
        color: black;
        background-color: rgb(255, 255, 255);
        height: 50px;
        width: 220px;
        padding-left: 10px;
        text-align: center;
      }

      .book-now-btn.book-massage:hover {
        background-color: rgba(255, 255, 255, 0);
        transition: all ease 0.5s;
      }
    }
  }

  @media (min-width: 900px) {
    .gift-card-section {
      .massages-p-container {
        flex-direction: row;
        gap: 10px;

        .massage-p-text-title {
          height: 50px;
          padding: 20px 0;
          width: 150px;
        }

        .massage-p-text-title.second-card {
          width: 300px;
          height: 70px;
          padding: 0;
        }

        .massage-p-text-description {
          height: 60px;
          padding: 20px 0 0 0;
        }

        .massage-p-text-description.second-card {
          height: 50px;
          padding: 0 0 0 0;
        }

        .gift-card-prices-container.second-card {
          height: 40px;
        }

        .gift-card-prices-container {
          height: 50px;
        }

        .massage-p-text {
          width: 100%;

          p {
            font-size: 12px;
          }
        }
      }

      .cart-btn {
        height: 80px;
        width: 220px;
        font-size: 22px;
      }
    }
  }

  @media (min-width: 1200px) {
    .gift-card-section {
      .massages-p-container {
        flex-direction: row;
        gap: 30px;

        .massage-p-container {
          height: 350px;
          width: 400px;

          .massage-p-text {
            height: 300px;
            width: 350px;

            p {
              font-size: 15px;
            }
          }

          .massage-p-text-title {
            height: 70px;
            padding: 0;
            width: 150px;
          }

          .massage-p-text-title.second-card {
            width: 100%;
            height: 70px;
            padding: 0;
          }

          .massage-p-text-description {
            min-height: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .massage-p-text-description.second-card {
            height: 50px;
            padding: 0 0 0 0;
          }

          .gift-card-prices-container.second-card {
            height: 40px;
          }

          .gift-card-prices-container {
            height: 50px;
          }
        }
      }
    }
  }

  @media (min-width: 2000px) {
    .gift-card-section {
      .massages-p-container {
        gap: 60px;

        .massage-p-container {
          height: 450px;
          width: 550px;

          .massage-p-text {
            height: 400px;
            width: 500px;
          }
        }
      }
    }
  }
}
