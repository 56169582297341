@import './common.scss';

.about-container {
  background-image: url('../assets/bc-images/bc1.webp');
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  gap: 20px;
}

.text-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6%;
  text-align: center;
}

.my-work-container {
  display: flex;
}

.portrait-image {
  width: 89%;
  border-radius: 3px;
  max-width: 700px;
}

.about-name {
  font-size: 30px;
  font-family: $playfair;
  margin-top: 20px;
  text-align: center;
}

.about-text {
  padding: 40px 0;
  text-align: center;
  max-width: 700px;
}

.paragraph-2 {
  margin-top: 10px;
}

.last-paragraph {
  margin: 0;
}

.opening-hours-container-about h3 {
  font-size: 18px;
  font-weight: 300;
}

@media (min-width: 768px) {
  .about-container {
    padding: 0 0 100px 0;
    background-image: url('../assets/bc-images/bc29.jpg');
  }

  .text-image {
    flex-direction: row;
    padding: 3%;
    gap: 20px;
  }

  .portrait-image {
    width: 50%;
  }

  .paragraph-2,
  .paragraph-1 {
    margin: 0;
    padding: 0;
  }

  .about-title {
    font-size: 70px;
    width: 500px;
    margin-top: 100px;
  }
}

@media (min-width: 1024px) {
  .about-container {
    background-image: url('../assets/bc-images/bc30.jpg');
    padding: 100px 0;
    background-size: cover;
  }

  .portrait-image {
    width: 450px;
    background-color: black;
  }

  .paragraph-2,
  .paragraph-1,
  .last-paragraph {
    padding: 6px;
    font-size: 16px;
  }

  .about-name {
    font-size: 40px;
  }

  .text-image {
    gap: 50px;
  }
}

@media (min-width: 1200px) {
  .portrait-image {
    width: 550px;
  }

  .about-title {
    font-size: 100px;
    width: 100%;
  }

  .paragraph-2,
  .paragraph-1,
  .last-paragraph {
    padding: 8px;
    font-size: 18px;
  }

  .about-name {
    font-size: 50px;
  }

  .text-image {
    gap: 50px;
  }
}

@media (min-width: 1400px) {
  .portrait-image {
    width: 550px;
  }

  .about-title {
    margin-top: 120px;
  }

  .about-name {
    font-size: 40px;
  }

  .text-image {
    gap: 70px;
  }
}
