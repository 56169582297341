@import 'common.scss';

.sp-section {
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url('../assets/bc-images/bc33.webp');
  background-size: cover;
  background-position: -500px 0;
  background-repeat: no-repeat;

  form {
    width: 100%;
    margin-top: 20px;
  }

  input,
  textarea {
    background-color: white;
    border: 1px solid rgb(48, 52, 80);
    width: 95%;
    height: 40px;
    border-radius: 20px;
  }

  input::placeholder,
  textarea::placeholder {
    color: rgb(48, 52, 80);
  }

  textarea {
    height: 200px;
  }

  .title-footer {
    color: black;
    margin: 0;
    font-size: 30px;
  }

  .submit-btn {
    background-color: white;
    border: 1px solid rgb(48, 52, 80);
    color: rgb(48, 52, 80);
    width: 150px;
    height: 40px;
    font-size: 20px;
    margin-top: 30px;
    margin-bottom: 60px;
  }

  .submit-btn:hover {
    background-color: rgb(48, 52, 80);
    border: 1px solid white;
    color: white;
  }

  .sophrology-main {
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
  }
}

.what-is,
.who-for {
  padding: 0 5% 0 5%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 800px;
  text-align: center;
}

.what-is-question,
.who-for-question {
  font-size: 21px;
  color: $chocolate;
  font-family: $playfair;
}

.image-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sp-image {
  width: 90%;
  box-shadow: 0 8px 16px 0 rgba(146, 249, 196, 0.107);
}

.offer-container {
  background-color: rgb(50, 64, 69);
  margin-top: 40px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 170px;
}

.offer-text {
  color: white;
  text-align: center;
  font-size: 20px;
  line-height: 25px;
}

.duration-container {
  background-color: white;
  width: 200px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -30px;
}

.extra-info-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.duration-text {
  color: rgb(50, 64, 69);
  width: 150px;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
}

.sessions {
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: center;
  align-items: center;
  background-color: #fafafa56;
  padding: 20px 0;
  margin: 0;
}

.follow-up,
.first-session {
  display: flex;
  width: 60%;
  gap: 10px;
}

.first-text,
.follow-text {
  font-size: 20px;
}

.fa-circle-dot {
  color: black;
  font-size: 16px;
  padding-top: 5px;
}

@media (min-width: 768px) {
  .sp-section {
    background-image: url('../assets/bc-images/bc34.webp');
    background-size: cover;
    background-position: -100px 0;

    .title-footer {
      font-size: 35px;
      color: rgb(50, 64, 69);
      margin-bottom: 40px;
    }

    input,
    textarea {
      height: 50px;
      border-radius: 25px;
      width: 500px;
    }

    textarea {
      height: 300px;
    }

    .sophrology-main {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 70px;
      padding: 0;
    }

    .sophrology-title-container {
      height: 300px;
      width: 500px;
    }

    .fa-circle-dot {
      color: black;
    }

    .what-is,
    .who-for {
      width: 600px;
    }

    .offer-container {
      padding: 0;
      margin: 0;
    }

    .sessions {
      width: 400px;
      border: 1px solid;
      gap: 0;
    }
  }
}

@media (min-width: 1000px) {
  .sp-section {
    background-position: 0 0;

    input,
    textarea {
      height: 50px;
      border-radius: 25px;
      width: 500px;
      max-width: none;
    }

    textarea {
      height: 300px;
    }
  }

  .what-is-question,
  .who-for-question {
    font-size: 23px;
  }

  .what-is,
  .who-for {
    width: 1000px;
  }

  .offer-container {
    width: 600px;
  }

  .sessions-container {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    gap: 100px;
  }

  .sessions {
    margin-top: 20px;
    border: 1px solid;
    color: black;
  }

  .follow-text,
  .first-text {
    padding: 0;
    margin: 0;
    color: black;
  }
}

@media (min-width: 1440px) {
  .sessions-container {
    margin: 0;
  }

  .sessions {
    width: 400px;
    gap: 20px;
  }

  .sophrology-title-container {
    background-position: 0 -300px;
  }

  .sophrology-title {
    font-size: 120px;
  }

  .sp-section {
    .title-footer {
      margin-top: 50px;
      font-size: 40px;
      color: rgb(50, 64, 69);
    }
  }
}
