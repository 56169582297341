@import './common.scss';

.footer-container {
  background-color: $blackFooter;
  color: white;

  .social-logos-container {
    .logo-container {
      background-color: $pink;

      .logo {
        color: #292929d3;
      }
    }
  }
}

.footer-items {
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  padding: 50px 20px 20px 20px;
}

.footer-item {
  color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.location-text-footer {
  color: white;
  font-family: $playfair;
  text-align: center;
}

.title-footer {
  color: white;
  font-family: $playfair;
  text-align: center;
}

.map-container-footer {
  border: 2px solid white;
}

.footer-item a {
  color: white;
  font-family: $playfair;
}

.map {
  transition: transform 0.5s ease-in-out;
  max-width: 300px;
}

.map:hover {
  transform: scale(1.3);
}

form,
.form-footer {
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.footer-input {
  background-color: $blackFooter;
  border: 1px solid $pink;
  width: 90%;
  max-width: 360px;
  color: $pink;
  border-radius: 20px;
  padding-left: 12px;
  font-size: 16px;
  height: 45px;
}

.footer-input::placeholder {
  color: $pink;
}

.textarea {
  height: 150px;
  padding-top: 5px;
}

.submit-btn {
  background-color: $pink;
  color: $blackFooter;
  font-family: $playfair;
  width: 120px;
  height: 30px;
  margin: 20px 0 30px 0;
  transition: all 0.5s ease-in-out;
}

.submit-btn:hover {
  color: $pink;
  background-color: $blackFooter;
  border: 1px solid $pink;
}

.submission-message-container {
  background-color: $pink;
  color: $blackFooter;
  padding: 5px 10px;
  margin: 15px;
}

.copyright-section {
  padding: 20px;
  text-align: center;
  margin-top: 50px;
}

.footer-list li {
  list-style: circle;
  color: wheat;
}

.footer-list a:hover {
  list-style: circle;
  color: wheat;
  font-size: 18px;
}

.title-line {
  display: none;
}

@media (min-width: 768px) {
  .footer-items {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 2fr;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
    row-gap: 0;
  }

  .footer-item {
    height: auto;
  }
}

@media (min-width: 1024px) {
  .footer-items {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    justify-items: center;
    align-items: flex-start;
    height: 500px;
  }

  .links {
    grid-column: 1;
    grid-row: 1/3;
    align-self: flex-start;
  }

  .contact {
    grid-column: 1;
    grid-row: 2;
  }

  .map-container {
    grid-column: 2;
    grid-row: 1/3;
    align-self: flex-start;
  }

  .form {
    grid-column: 3;
    grid-row: 1/3;
    align-self: center;
  }

  .footer-input {
    width: 300px;
  }
}

@media (min-width: 1440px) {
  .map-container-footer {
    height: 200px;
    width: 300px;
  }
}
