@import './common.scss';

.massage-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10% 3% 20% 3%;
  gap: 40px;
  background-image: url('../assets/bc-images/bc8.webp');
  background-size: cover;
  background-position: 0 0;
  background-repeat: no-repeat;
}

.massages-p-container {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.massage-p-container {
  height: 500px;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.wellness {
  background-image: url('../assets/images/massage1.jpeg');
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
}

.customized {
  background-image: url('../assets/images/massage2.jpeg');
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
}

.ayurvedic {
  background-image: url('../assets/images/massage3.jpeg');
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
}

.massage-p-text {
  background-color: rgba(204, 200, 198, 0.429);
  text-align: center;
  width: 95%;
  height: 280px;
  font-family: $playfair;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
}

.massage-p-text-description {
  margin: 4px;
}

.massage-p-title-text {
  font-family: $playfair;
  font-size: 50px;
  margin-top: 85px;
  font-weight: 400;
  text-transform: uppercase;
}

.massage-p-text-title {
  font-size: 23px;
  text-transform: uppercase;
  font-family: $playfair;
}

.time-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.book-now-btn.book-massage {
  width: 200px;
  height: 35px;
  margin: 0;
  color: rgb(117, 114, 110);
  background-color: rgb(246, 233, 225);
  font-size: 17px;
  border: 1px solid rgb(117, 114, 110);
  display: flex;
  justify-content: center;
  align-items: center;
}

.times-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.time-slots {
  display: flex;
  gap: 10px;
}

@media (min-width: 768px) {
  .massage-section {
    background-image: url('../assets/bc-images/bc8.webp');
    background-size: cover;
    background-position: -100px 0;
    background-repeat: no-repeat;
  }

  .time-slots {
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 0 10px;
  }

  .wellness,
  .customized,
  .ayurvedic {
    background-image: none;
  }

  .massage-p-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
    max-width: none;
    width: 100%;

    .image-desktop-wellness {
      background-image: url('../assets/images/massage1.jpeg');
      background-position: 0 0;
      background-repeat: no-repeat;
      background-size: cover;
      width: 400px;
      height: 400px;
    }

    .image-desktop-customized {
      background-image: url('../assets/images/massage2.jpeg');
      background-position: 0 0;
      background-repeat: no-repeat;
      background-size: cover;
      width: 400px;
      height: 400px;
    }

    .image-desktop-ayurvedic {
      background-image: url('../assets/images/massage3.jpeg');
      background-position: 0 0;
      background-repeat: no-repeat;
      background-size: cover;
      width: 400px;
      height: 400px;
    }

    .massage-p-text {
      width: 40%;
      height: auto;
      background-color: rgba(245, 222, 179, 0);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }

    .book-now-btn.book-massage {
      color: black;
      background-color: rgb(255, 255, 255);
      height: 50px;
      width: 220px;
      padding-left: 10px;
      text-align: center;
    }

    .book-now-btn.book-massage:hover {
      background-color: rgba(255, 255, 255, 0);
      transition: all ease 0.5s;
    }
  }

  @media (min-width: 1000px) {
    .massage-section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 10% 3% 20% 3%;

      .massages-p-container {
        gap: 50px;
        margin-top: 70px;

        .massage-p-container {
          gap: 120px;

          .massage-p-text {
            height: auto;
            width: 350px;
            background-color: rgba(245, 222, 179, 0);
          }

          .image-desktop-wellness,
          .image-desktop-customized,
          .image-desktop-ayurvedic {
            width: 500px;
            height: 500px;
          }

          .image-desktop-wellness:hover,
          .image-desktop-customized:hover,
          .image-desktop-ayurvedic:hover {
            filter: grayscale(0.3);
            border: 2px solid rgba(0, 0, 0, 0.373);
            transition: all ease 0.5s;
          }
        }
      }
    }
  }
}

@media (min-width: 1500px) {
  .massage-section {
    background-position: 0 0;
  }
}

@media (min-width: 2000px) {
  .massage-section {
    .massages-p-container {
      gap: 70px;
      margin-top: 100px;

      .massage-p-container {
        gap: 160px;
        height: auto;

        .massage-p-text {
          width: 450px;
        }

        .image-desktop-wellness,
        .image-desktop-customized,
        .image-desktop-ayurvedic {
          width: 700px;
          height: 700px;
        }
      }
    }
  }

  .massage-p-text-title {
    font-size: 35px;
  }

  .massage-p-text-description,
  .time-text {
    font-size: 25px;
  }
}
