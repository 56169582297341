
.error-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url('../assets/bc-images/bc111.webp');
  background-position: 0 0;
  background-size: cover;
  min-height: 700px;
  gap: 20px;

  h1 {
    font-size: 140px;
    color: rgb(255, 255, 255);
  }

  p {
    color: white;
    font-size: 30px;
  }

  .error {
    background-color: rgba(255, 255, 255, 0.761);
    color: black;
    width: 120px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
