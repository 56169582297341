@import './common.scss';

.opening-hours-container-about {
  width: 250px;
  border: 1px solid #b2ba99;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4%;
  background-color: rgba(255, 255, 255, 0.644);
}

.opening-hours-container-about h2,
h3,
p {
  font-family: $playfair;
}

.hours-div {
  display: flex;
  gap: 5px;

  .hours {
    margin-bottom: 7px;
    padding: 0;
  }
}

.hours-location-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  background-image: url('../assets/bc-images/bc3.png');
  background-position: 0 10px;
  background-repeat: no-repeat;
  background-size: cover;
  height: 450px;
}

@media (min-width: 380px) {
  .hours-location-container {
    background-position: 0 0;
  }

  .opening-hours-container-about {
    width: 300px;
  }
}

@media (min-width: 500px) {
  .hours-location-container {
    background-position: 0 -140px;
  }
}

@media (min-width: 600px) {
  .hours-location-container {
    background-position: 0 -300px;
  }
}

@media (min-width: 700px) {
  .hours-location-container {
    background-position: 0 -400px;
  }
}

@media (min-width: 768px) {
  .opening-hours-container-about {
    width: 350px;
    border: 1px solid #b2ba99;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4%;
    margin-bottom: 50px;
    background-color: rgba(255, 255, 255, 0.644);
  }

  .hours-location-container {
    background-image: url('../assets/bc-images/bc3.png');
    background-position: 0 -500px;
    background-size: 100%;
  }
}

@media (min-width: 900px) {
  .opening-hours-container-about {
    width: 350px;
    border: 1px solid #b2ba99;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4%;
    margin-bottom: 50px;
    background-color: rgba(255, 255, 255, 0.644);
    transition: transform 0.5s ease-in-out;
  }

  .opening-hours-container-about:hover {
    background-color: rgb(255, 255, 255);
    transform: scale(1.1);
  }

  .hours-location-container {
    background-image: url('../assets/bc-images/bc3.png');
    background-position: 0 -500px;
    background-size: 100%;
  }

  @media (min-width: 1000px) {
    .hours-location-container {
      background-position: 0 -600px;
    }

    .opening-hours-container-about {
      margin-top: 50px;
      padding: 2%;
    }
  }

  @media (min-width: 1100px) {
    .hours-location-container {
      background-position: 0 -650px;
    }
  }

  @media (min-width: 1200px) {
    .hours-location-container {
      background-position: 0 -750px;
    }
  }

  @media (min-width: 1300px) {
    .hours-location-container {
      background-position: 0 -850px;
    }

    @media (min-width: 1500px) {
      .hours-location-container {
        background-position: 0 -950px;
      }
    }

    @media (min-width: 1600px) {
      .hours-location-container {
        background-position: 0 -1000px;
      }
    }

    @media (min-width: 1650px) {
      .hours-location-container {
        background-position: 0 -1050px;
      }
    }

    @media (min-width: 1700px) {
      .hours-location-container {
        background-position: 0 -1100px;
      }
    }

    @media (min-width: 1800px) {
      .hours-location-container {
        background-position: 0 -1150px;
      }
    }

    @media (min-width: 1900px) {
      .hours-location-container {
        background-position: 0 -1250px;
      }
    }

    @media (min-width: 2000px) {
      .hours-location-container {
        background-position: 0 -1240px;
      }
    }

    @media (min-width: 2100px) {
      .hours-location-container {
        background-position: 0 -1340px;
      }
    }

    @media (min-width: 2200px) {
      .hours-location-container {
        background-position: 0 -1440px;
      }
    }

    @media (min-width: 2300px) {
      .hours-location-container {
        background-position: 0 -1550px;
      }
    }

    @media (min-width: 2400px) {
      .hours-location-container {
        background-position: 0 -1640px;
      }
    }

    @media (min-width: 2500px) {
      .hours-location-container {
        background-position: 0 -1740px;
      }
    }
  }
}
