@import './common.scss';

.nav-mobile {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 4%;
  overflow-y: hidden;
  z-index: 999;
  background-color: $pink;
}

.logo-navbar {
  width: 100px;
}

.logo-mobile {
  width: 75px;
}

.logo-mobile-popup {
  width: 140px;
}

.logo-navbar-mobile {
  width: 100%;
}

.hamburger-react {
  color: rgb(246, 234, 225);
}

.burger-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1000;
  top: 10px;
  right: 10px;
}

.menu {
  display: flex;
  flex-direction: column;
  gap: 25px;
  align-items: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: relative;
  justify-content: space-around;
}

.menu a {
  text-decoration: none;
  font-family: $playfair;
  font-size: 22px;
  color: rgb(94, 76, 65);
}

.menu a:hover {
  color: $pink;
}

.mobile-menu {
  background-image: url('../assets/bc-images/mobile-menu.png');
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  z-index: 10;
  transform: translateX(100%);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.mobile-menu.open {
  transform: translateX(0%);
  opacity: 1;
}

.logo-navbar.open {
  opacity: 0;
}

.mobile-menu li {
  opacity: 0;
}

.menu li {
  list-style: none;
  transform: translateY(-30px);
  transition: opacity 0.7s ease-in-out, transform 0.5s ease-in-out;
}

.menu li:nth-child(2) {
  transition-delay: 0.5s;
}

.menu li:nth-child(3) {
  transition-delay: 0.7s;
}

.menu li:nth-child(4) {
  transition-delay: 0.8s;
}

.menu li:nth-child(5) {
  transition-delay: 1s;
}

.menu li:nth-child(6) {
  transition-delay: 1.2s;
}

.menu li:nth-child(7) {
  transition-delay: 1.5s;
}

.mobile-menu.open li {
  opacity: 1;
  transform: translateY(0);
}

@media (min-width: 600px) {
  .nav-mobile {
    padding: 2%;
  }
}

@media (min-width: 1000px) {
  .logo-mobile-popup {
    width: 160px;
  }

  .hamburger-react {
    font-size: 50px;
  }

  .menu {
    gap: 60px;
  }

  .menu a {
    font-size: 30px;
  }

  .burger-container {
    top: 20px;
    right: 20px;
  }
}

@media (min-width: 900px) {
  .nav-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 2%;
    background-color: $pink;
    z-index: 10;
    position: fixed;
    width: 100%;
  }

  .nav-item:hover {
    filter: none;
    transition: filter 0.5s ease-in-out;
  }

  .nav-bar:hover .nav-item:not(:hover) {
    filter: blur(1px);
    transition: filter 0.5s ease-in-out;
  }

  .menu-navbar {
    display: flex;
    flex-direction: row;
    gap: 40px;
    justify-content: space-between;
    align-items: center;
    margin: 0;

    @media screen and (max-width: 900px) {
      gap: 21.5px;
    }
  }

  .menu-navbar a {
    font-family: $playfair;
    font-size: 16px;
    color: rgb(117, 114, 110);
  }

  .menu-navbar a:hover {
    color: rgb(117, 114, 110);
  }

  @media (min-width: 1200px) {
    .nav-bar {
      padding: 10px 4%;
    }

    .menu-navbar {
      gap: 60px;
    }

    .menu-navbar a {
      font-size: 18px;
    }

    @media (min-width: 1441px) {
      .nav-bar {
        padding: 15px 4%;
      }

      .logo-navbar {
        width: 120px;
      }

      .menu-navbar {
        gap: 80px;

        a {
          font-size: 22px;
        }
      }
    }
  }
}
